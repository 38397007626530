import React from "react";
import PropTypes from "prop-types";

import "./Logo.scss";
import SVGLogo from "content/assets/svg/logo.svg";

const Logo = ({ brand }) => {
  return <SVGLogo alt={brand} className="logo" />;
};

Logo.propTypes = {
  brand: PropTypes.string,
};

Logo.defaultProps = {
  brand: null,
};

export default Logo;
